<template>
  <div class="container">
    <div class="tl spread">
      <div style="margin: 0 20px">
        <input
          v-model="gtin"
          placeholder="条形码/二维码值"
          style="width: 200px; background-color: transparent"
          class="input_box"
        />
        <input
          v-model="batchNumber"
          placeholder="批次"
          style="width: 200px; background-color: transparent"
          class="input_box"
        />
        <input
          v-model="keyword"
          placeholder="关键词"
          style="width: 240px; background-color: transparent"
          class="input_box"
        />
        <span class="button" @click="handleSearch">查询</span>
        <span class="button" @click="handleRest">重置</span>
      </div>
      <div class="spread">
        <ve-bmap
          v-if="mapShow"
          ref="mapRef"
          height="98%"
          :settings="chartSettings"
          :series="chartSeries"
          :tooltip="chartTooltip"
          :events="chartEvents"
        >
          <div
            class="chain-block-box"
            v-show="companyList && companyList.length > 0"
          >
            Hash值区块链验证:
            <span v-if="!hashFlag" class="hash-value" @click="verifyHash">
              <i class="el-icon-thumb"></i>
              {{ hashVal }}
            </span>
            <span v-else class="hash-result" @click="verifyHash"
              >数据无篡改</span
            >
          </div>
          <div class="default-desc col-fff tc">默认显示最新50条流向记录</div>
        </ve-bmap>
      </div>
    </div>
    <el-dialog
      title="委托详情"
      :visible.sync="dailyVisible"
      width="1500px"
      custom-class="daily-box"
      @closed="entrustDetail = null"
    >
      <div
        v-loading="nextLoading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        style="
          background-color: #fff;
          height: 800px;
          margin-left: 10px;
          border-radius: 10px;
        "
      >
        <div
          class="df aic jcc"
          style="height: 100%; width: 100%"
          v-if="!entrustDetail"
        >
          <el-empty description="暂未填写"></el-empty>
        </div>
        <div
          v-else
          class="daily-detail"
          style="padding: 20px; height: 100%; overflow-y: scroll"
        >
          <el-card class="box-card" style="margin-bottom: 20px">
            <div slot="header">
              <div class="df">基本信息</div>
            </div>
            <div>
              <el-form ref="form" :model="entrustDetail" label-width="80px">
                <el-form-item label="委托企业">
                  <el-input
                    style="width: 80%"
                    v-model="entrustDetail.companyName"
                    readonly
                  ></el-input>
                </el-form-item>
                <el-form-item label="受托企业">
                  <el-input
                    style="width: 80%"
                    v-model="entrustDetail.entrustedCompanyName"
                    readonly
                  ></el-input>
                </el-form-item>
                <el-form-item label="委托业务">
                  <el-input
                    style="width: 80%"
                    v-model="entrustDetail.entrustTitle"
                    readonly
                  ></el-input>
                </el-form-item>
                <el-form-item label="业务描述">
                  <el-input
                    style="width: 80%"
                    v-model="entrustDetail.entrustContent"
                    type="textarea"
                    readonly
                    autosize
                  ></el-input>
                </el-form-item>
              </el-form>
            </div>
          </el-card>
          <el-card
            class="box-card"
            style="margin-bottom: 20px"
            v-if="entrustDetail.mode !== null"
          >
            <div slot="header">
              <div class="df">发货信息</div>
            </div>
            <el-form ref="form" :model="entrustDetail" label-width="80px">
              <el-form-item label="发货类型">
                <el-input
                  style="width: 80%"
                  v-model="['自主货运', '快递寄送'][entrustDetail.mode]"
                  readonly
                ></el-input>
              </el-form-item>
              <el-form-item
                label="发货实景"
                v-if="
                  entrustDetail.imgList &&
                  JSON.parse(entrustDetail.imgList).length
                "
              >
                <div
                  style="
                    width: 90%;
                    padding-left: 130px;
                    justify-content: flex-start;
                    flex-wrap: wrap;
                  "
                  class="df aic"
                >
                  <div
                    style="
                      width: 100px;
                      height: 100px;
                      margin: 10px;
                      border-radius: 10px;
                      overflow: hidden;
                    "
                    v-for="(item, index) in JSON.parse(entrustDetail.imgList)"
                    :key="index"
                  >
                    <img
                      :src="item"
                      style="
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        cursor: pointer;
                      "
                      alt=""
                      @click="handlePreviewImage(item)"
                    />
                  </div>
                </div>
              </el-form-item>
              <el-form-item label="运输车辆" v-if="!entrustDetail.mode">
                <el-input
                  style="width: 80%"
                  v-model="entrustDetail.carNumber"
                  readonly
                ></el-input>
              </el-form-item>
              <el-form-item label="挂车牌号" v-if="!entrustDetail.mode">
                <el-input
                  style="width: 80%"
                  placeholder="未填写"
                  v-model="entrustDetail.guaNumber"
                  readonly
                ></el-input>
              </el-form-item>
              <el-form-item label="备注">
                <el-input
                  style="width: 80%"
                  v-model="entrustDetail.remark"
                  placeholder="未填写"
                  type="textarea"
                  readonly
                  autosize
                ></el-input>
              </el-form-item>
            </el-form>
          </el-card>
          <el-card class="box-card" style="margin-bottom: 20px">
            <div slot="header">
              <div class="df">相关货品</div>
            </div>
            <div
              class="df aic"
              style="
                margin: 10px;
                padding: 20px;
                margin: 10px;
                border-bottom: 1px solid #eee;
              "
              v-for="(item, index) in entrustDetail.infoList"
              :key="index"
            >
              <div
                style="
                  width: 100px;
                  height: 100px;
                  border-radius: 10px;
                  overflow: hidden;
                "
              >
                <img
                  :src="JSON.parse(item.urls)[0]"
                  style="width: 100%; height: 100%; object-fit: cover"
                  alt=""
                />
              </div>
              <div style="flex: 1; width: 0" class="goodsInfo">
                <div class="df aic" style="line-height: 24px">
                  <div
                    class="df"
                    style="
                      justify-content: flex-end;
                      width: 80px;
                      margin-right: 20px;
                    "
                  >
                    商品名
                  </div>
                  <div class="df" style="justify-content: flex-start">
                    {{ item.name }}
                  </div>
                </div>
                <div class="df aic" style="line-height: 24px">
                  <div
                    class="df"
                    style="
                      justify-content: flex-end;
                      width: 80px;
                      margin-right: 20px;
                    "
                  >
                    商品编码
                  </div>
                  <div class="df" style="justify-content: flex-start">
                    {{ item.gtin }}
                  </div>
                </div>
                <div class="df aic" style="line-height: 24px">
                  <div
                    class="df"
                    style="
                      justify-content: flex-end;
                      width: 80px;
                      margin-right: 20px;
                    "
                  >
                    规格
                  </div>
                  <div class="df" style="justify-content: flex-start">
                    {{ item.specification ? item.specification : "未填写" }}
                  </div>
                </div>
                <div class="df aic" style="line-height: 24px">
                  <div
                    class="df"
                    style="
                      justify-content: flex-end;
                      width: 80px;
                      margin-right: 20px;
                    "
                  >
                    商品类型
                  </div>
                  <div class="df" style="justify-content: flex-start">
                    {{ item.categoryName ? item.categoryName : "未填写" }}
                  </div>
                </div>
                <div class="df aic" style="line-height: 24px">
                  <div
                    class="df"
                    style="
                      justify-content: flex-end;
                      width: 80px;
                      margin-right: 20px;
                    "
                  >
                    批次信息
                  </div>
                  <div
                    class="df"
                    style="justify-content: flex-start; flex-wrap: wrap"
                  >
                    <el-tag
                      v-for="(tItem, tIndex) in item.batch"
                      :key="tIndex"
                      style="margin: 10px 0; margin-right: 10px"
                      >{{ tItem.batchNumber
                      }}<span style="margin: 0 5px">|</span
                      >{{ tItem.goodsWeight }}{{ tItem.unitName }}</el-tag
                    >
                  </div>
                </div>
              </div>
            </div>
          </el-card>
          <el-card
            class="box-card"
            style="margin-bottom: 20px"
            v-if="entrustDetail.otherInfo && entrustDetail.otherInfo !== '[]'"
          >
            <div slot="header">
              <div class="df">其他信息</div>
            </div>
            <div style="padding: 0 10px; background-color: #f8f8f8">
              <div
                style="background-color: #fff"
                v-for="(oItem, oIndex) in entrustDetail.otherInfo"
                :key="oIndex"
              >
                <div style="height: 10px; background-color: #f8f8f8"></div>
                <div style="padding:5px;10px;border-bottom: 1px solid #eee;">
                  {{ oItem.title }}
                </div>
                <div v-if="oItem.type === 0" style="padding: 10px">
                  <el-input
                    v-model="oItem.data"
                    type="textarea"
                    readonly
                    autosize
                  ></el-input>
                </div>
                <div
                  v-if="oItem.type === 1"
                  class="df aic"
                  style="flex-wrap: wrap"
                >
                  <div
                    style="
                      width: 100px;
                      height: 100px;
                      margin: 10px;
                      border-radius: 10px;
                      overflow: hidden;
                    "
                    v-for="(iItem, iIndex) in JSON.parse(oItem.data)"
                    :key="iIndex"
                  >
                    <img
                      :src="iItem"
                      style="
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        cursor: pointer;
                      "
                      alt=""
                      @click="handlePreviewImage(iItem)"
                    />
                  </div>
                </div>
                <div
                  v-if="oItem.type === 2"
                  class="df aic"
                  style="flex-wrap: wrap"
                >
                  <div
                    style="
                      width: 220px;
                      height: 140px;
                      margin: 10px;
                      border-radius: 10px;
                      overflow: hidden;
                      background-color: #000;
                    "
                    v-for="(vItem, vIndex) in JSON.parse(oItem.data)"
                    :key="vIndex"
                  >
                    <video
                      :src="vItem"
                      style="width: 100%; height: 100%"
                      controls
                    ></video>
                  </div>
                </div>
              </div>
              <div style="height: 10px; background-color: #f8f8f8"></div>
            </div>
          </el-card>
          <el-card
            v-if="entrustDetail.recordList && entrustDetail.recordList.length"
            class="box-card"
            style="margin-bottom: 20px"
          >
            <div slot="header">
              <div class="df">处理记录</div>
            </div>
            <el-steps
              direction="vertical"
              :active="entrustDetail.recordList.length"
            >
              <el-step
                :title="rItem.name"
                v-for="(rItem, rIndex) in entrustDetail.recordList"
                :key="rIndex"
              >
                <template #description>
                  <el-form ref="form" :model="entrustDetail" label-width="80px">
                    <el-form-item label="处理时间">
                      <span style="color: #000">
                        {{ rItem.createdTime.replace("T", " ") }}
                      </span>
                    </el-form-item>
                    <el-form-item
                      label="处理实景"
                      v-if="rItem.imgList && JSON.parse(rItem.imgList).length"
                    >
                      <div
                        style="
                          width: 90%;
                          justify-content: flex-start;
                          flex-wrap: wrap;
                        "
                        class="df aic"
                      >
                        <div
                          style="
                            width: 100px;
                            height: 100px;
                            margin: 10px;
                            border-radius: 10px;
                            overflow: hidden;
                          "
                          v-for="(item, index) in JSON.parse(rItem.imgList)"
                          :key="index"
                        >
                          <img
                            :src="item.url"
                            style="
                              width: 100%;
                              height: 100%;
                              object-fit: cover;
                              cursor: pointer;
                            "
                            alt=""
                            @click="handlePreviewImage(item.url)"
                          />
                        </div>
                      </div>
                    </el-form-item>
                    <el-form-item
                      label="处理实录"
                      v-if="
                        rItem.videoList && JSON.parse(rItem.videoList).length
                      "
                    >
                      <div
                        style="
                          width: 90%;
                          justify-content: flex-start;
                          flex-wrap: wrap;
                        "
                        class="df aic"
                      >
                        <div
                          style="
                            width: 220px;
                            height: 140px;
                            margin: 10px;
                            border-radius: 10px;
                            overflow: hidden;
                            background-color: #000;
                          "
                          v-for="(vItem, vIndex) in JSON.parse(rItem.videoList)"
                          :key="vIndex"
                        >
                          <video
                            :src="vItem.url"
                            style="width: 100%; height: 100%"
                            controls
                          ></video>
                        </div>
                      </div>
                    </el-form-item>
                    <el-form-item label="处理记录" v-if="rItem.remark">
                      <el-input
                        style="width: 80%"
                        v-model="rItem.remark"
                        placeholder="未填写"
                        type="textarea"
                        readonly
                        autosize
                      ></el-input>
                    </el-form-item>

                    <el-form-item>
                      <template #label>
                        <div style="line-height: 16px">采集点</div>
                        <div style="line-height: 16px">GPS</div>
                      </template>
                      <span style="color: #000">
                        {{ rItem.longitude }},{{ rItem.latitude }}
                      </span>
                    </el-form-item>
                  </el-form>
                </template>
              </el-step>
            </el-steps>
          </el-card>
          <el-card
            v-if="entrustDetail.finishedInfo"
            class="box-card"
            style="margin-bottom: 20px"
          >
            <div slot="header">
              <div class="df">
                完成报告 - {{ entrustDetail.finishedInfo.title }}
              </div>
            </div>
            <div>
              <el-form
                ref="form"
                :model="entrustDetail.finishedInfo"
                label-width="80px"
              >
                <el-form-item
                  label="相关图片"
                  v-if="
                    entrustDetail.finishedInfo.imgUrls &&
                    JSON.parse(entrustDetail.finishedInfo.imgUrls).length
                  "
                >
                  <div
                    style="
                      width: 90%;
                      justify-content: flex-start;
                      flex-wrap: wrap;
                    "
                    class="df aic"
                  >
                    <div
                      style="
                        width: 100px;
                        height: 100px;
                        margin: 10px;
                        border-radius: 10px;
                        overflow: hidden;
                      "
                      v-for="(item, index) in JSON.parse(
                        entrustDetail.finishedInfo.imgUrls
                      )"
                      :key="index"
                    >
                      <img
                        :src="item"
                        style="
                          width: 100%;
                          height: 100%;
                          object-fit: cover;
                          cursor: pointer;
                        "
                        alt=""
                        @click="handlePreviewImage(item)"
                      />
                    </div>
                  </div>
                </el-form-item>
                <el-form-item
                  label="相关文件"
                  v-if="
                    entrustDetail.finishedInfo.fileUrls &&
                    JSON.parse(entrustDetail.finishedInfo.fileUrls).length
                  "
                >
                  <div
                    v-for="(item, index) in JSON.parse(
                      entrustDetail.finishedInfo.fileUrls
                    )"
                    @click="open(item.url)"
                    class="df aic"
                    :key="index"
                    style="cursor: pointer; margin-bottom: 10px"
                  >
                    <i
                      class="el-icon-document"
                      style="font-size: 30px; color: #0081ff"
                    ></i>
                    <span>{{ item.fileName }}</span>
                  </div>
                </el-form-item>
              </el-form>
            </div>
          </el-card>
        </div>
      </div>
    </el-dialog>
    <el-image-viewer
      style="z-index: 9999"
      v-if="showViewer1"
      :on-close="closeViewer1"
      :url-list="url1"
    />
  </div>
</template>

<script>
import { createHash } from "@/util/util";
import { mapConfig } from "./custom_map_config.js";
import api from "../../../util/extra-api";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";

export default {
  components: {
    ElImageViewer,
  },
  data() {
    this.chartSettings = {
      key: "Ns87bUghsWzPirriDs7uPGPYQOlGUKYQ",
      bmap: {
        center: [120, 30],
        zoom: 5,
        roam: true,
        enableMapClick: false,
        mapStyleV2: { styleJson: mapConfig },
      },
    };
    this.chartTooltip = {
      show: true,
      formatter: function (params, ticket, callback) {
        if (params.seriesType === "effectScatter") {
          // return `${params.marker}${params.value[2]}<br>${
          //   params.value[5] || params.value[6]
          // }-${params.value[3]}-${params.value[4]}`;
        } else if (params.seriesType === "lines") {
          return `${params.data.fromName} > ${params.data.toName}<br>`;
          //   发货车辆:${params.data.soCarNumber || "无车辆信息"}-收货车辆:${params.data.rcCarNumber || "无车辆信息"}`;
        }
      },
    };
    const _this = this;
    this.chartEvents = {
      click: (v) => {
        // console.log(v.data);
        if (v.data.entrustId) {
          _this.dailyVisible = true;
          _this.nextLoading = true;
          _this.getEntrustDetail(v.data.entrustId);
        //   console.log(v.data.entrustId);
        }
      },
      finished: (_) => {},
    };
    return {
      gtin: "",
      keyword: "",
      batchNumber: "",
      mapShow: false,
      companyList: [],
      chartSeries: [],
      hashFlag: false,
      hashVal: createHash(30),
      dailyVisible: false,
      entrustDetail: null,
      nextLoading: false,
      url1: "",
      showViewer1: false,
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.initMap();
  },
  methods: {
    open(url) {
      window.open(url);
    },
    closeViewer1() {
      this.showViewer1 = false;
      this.url1 = [];
    },
    handlePreviewImage(url) {
      this.url1 = [url];
      this.showViewer1 = true;
    },
    getEntrustDetail(id) {
      api.get("/v1/pc/entrust/detail", { id }).then((res) => {
        if (res.code === 200) {
          this.entrustDetail = res.data;
          let arr = [...new Set(res.data.infoList.map((v) => v.goodsId))];
          let goodsList = [];
          if (this.entrustDetail.finishedInfo) {
            this.entrustDetail.finishedInfo = JSON.parse(
              this.entrustDetail.finishedInfo
            );
          }
          arr.forEach((v) => {
            let list = res.data.infoList.filter((item) => item.goodsId === v);
            let gtinInfo = JSON.parse(list[0].gtinInfo);
            let data = {
              name: gtinInfo.goodsName,
              specification: gtinInfo.spec,
              categoryName: gtinInfo.goodsType,
              urls: JSON.stringify(gtinInfo.imgList),
              goodsId: v,
              gtin: gtinInfo.code,
              batch: list.map((item, index) => {
                return {
                  batchNumber: item.batchNumber,
                  goodsId: item.goodsId,
                  goodsName: item.goodsName,
                  gtinInfo: item.gtinInfo,
                  goodsWeight: String(item.num),
                  unitName: item.unit,
                  key: list[0].goodsId + "I" + index,
                };
              }),
            };
            goodsList.push(data);
          });
          this.entrustDetail.infoList = goodsList;

          if (
            this.entrustDetail.otherInfo &&
            this.entrustDetail.otherInfo !== "[]"
          ) {
            this.entrustDetail.otherInfo = JSON.parse(
              this.entrustDetail.otherInfo
            );
          } else {
            this.entrustDetail.otherInfo = null;
          }
          this.nextLoading = false;
          this.getRecordList(id);
        }
      });
    },
    getRecordList(entrustId) {
      api.get("/v1/pc/entrust/recordList", { entrustId }).then((res) => {
        if (res.code === 200) {
          this.$set(this.entrustDetail, "recordList", res.data);
        }
      });
    },
    initMap(params) {
      const loading = this.$loading({
        target: document.querySelector(".container"),
        lock: true,
        text: "数据加载中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.mapShow = false;
      this.chartSeries = [];
      this.$extraApi
        .get(`/v1/pc/flow/entrustFlow`, {
          opt: 2,
          gtin: this.gtin,
          keyword: this.keyword,
          batchNumber: this.batchNumber,
        })
        .then((response) => {
          let res = response.data;
          if (res.length > 0) {
            let convertData = this.convertData(res);
            let arr = [];
            let planePath =
              "path://M.6,1318.313v-89.254l-319.9-221.799l0.073-208.063c0.521-84.662-26.629-121.796-63.961-121.491c-37.332-0.305-64.482,36.829-63.961,121.491l0.073,208.063l-319.9,221.799v89.254l330.343-157.288l12.238,241.308l-134.449,92.931l0.531,42.034l175.125-42.917l175.125,42.917l0.531-42.034l-134.449-92.931l12.238-241.308L1705";
            convertData.forEach((item, index) => {
              arr.push(
                {
                  type: "effectScatter",
                  coordinateSystem: "bmap",
                  zlevel: 2,
                  rippleEffect: {
                    //涟漪特效
                    period: 4, //动画时间，值越小速度越快
                    brushType: "stroke", //波纹绘制方式 stroke, fill
                    scale: 4, //波纹圆环最大限制，值越大波纹越大
                  },
                  symbol: "circle",
                  itemStyle: {
                    normal: {
                      color: "#3ed4ff",
                    },
                  },
                  data: item.fromData,
                },
                {
                  type: "effectScatter",
                  coordinateSystem: "bmap",
                  zlevel: 2,
                  rippleEffect: {
                    //涟漪特效
                    period: 4, //动画时间，值越小速度越快
                    brushType: "stroke", //波纹绘制方式 stroke, fill
                    scale: 4, //波纹圆环最大限制，值越大波纹越大
                  },
                  symbol: "circle",
                  itemStyle: {
                    normal: {
                      color: "#3ed4ff",
                    },
                  },
                  data: item.toData,
                },
                {
                  type: "lines",
                  coordinateSystem: "bmap",
                  zlevel: 1,
                  effect: {
                    show: true,
                    period: 6,
                    trailLength: 0.7,
                    color: "#fff",
                    symbolSize: 3,
                  },
                  lineStyle: {
                    normal: {
                      color: "#3ed4ff",
                      width: 0,
                      curveness: 0.2,
                    },
                  },
                  data: item.lineData,
                },
                {
                  type: "lines",
                  coordinateSystem: "bmap",
                  zlevel: 2,
                  effect: {
                    show: true,
                    period: 6,
                    trailLength: 0,
                    symbol: planePath,
                    symbolSize: 15,
                  },
                  lineStyle: {
                    normal: {
                      color: "#3ed4ff",
                      width: 1,
                      opacity: 0.4,
                      curveness: 0.2,
                    },
                  },
                  data: item.lineData,
                }
                // {
                //   type: "lines",
                //   coordinateSystem: "bmap",
                //   zlevel: 1,
                //   effect: {
                //     show: true,
                //     period: 6, //箭头指向速度，值越小速度越快
                //     trailLength: 0.7, //特效尾迹长度[0,1]值越大，尾迹越长重
                //     // symbol: "arrow", //箭头图标
                //     color: "#fff",
                //     symbolSize: 5, //图标大小
                //   },
                //   lineStyle: {
                //     normal: {
                //       color: "#3ed4ff",
                //       width: 1, //尾迹线条宽度
                //       // opacity: 1, //尾迹线条透明度
                //       curveness: 0.3, //尾迹线条曲直度
                //     },
                //   },
                //   data: item.lineData,
                // }
              );
            });
            this.chartSeries = arr;
          }
          this.mapShow = true;
          this.getGeo();
        });
      loading.close();
    },
    handleSearch() {
      this.initMap();
    },
    handleRest() {
      this.gtin = "";
      this.keyword = "";
      this.batchNumber = "";
      this.initMap();
    },
    afterSet: function (echarts) {
      var bmap = echarts.getModel().getComponent("bmap").getBMap();
      bmap.addControl(new window.BMap.MapTypeControl());
    },
    convertData(data) {
      let result = [];
      let companyList = [];
      let afterData = [];
      let tempArr = [];
      // for (let i = 0; i < data.length; i++) {
      //   if (
      //     tempArr.findIndex(
      //       (t) => t.fromCompanyId == data[i].from.company_id
      //     ) === -1 &&
      //     tempArr.findIndex((t) => t.toCompanyId == data[i].to.company_id) ===
      //       -1
      //   ) {
      //     afterData.push({
      //       from: data[i].from,
      //       to: data[i].to,
      //       info: {
      //         goods_name: data[i].info.goods_name,
      //         batches: [data[i].info.batch],
      //       },
      //     });
      //     tempArr.push({
      //       fromCompanyId: data[i].from.company_id,
      //       toCompanyId: data[i].to.company_id,
      //     });
      //   } else {
      //     for (let j = 0; j < afterData.length; j++) {
      //       if (
      //         afterData[j].from.company_id == data[i].from.company_id &&
      //         afterData[j].to.company_id == data[i].to.company_id
      //       ) {
      //         afterData[j].info.batches.push(data[i].info.batch);
      //         break;
      //       }
      //     }
      //   }
      // }
      // data = afterData;
      for (let i = 0; i < data.length; i++) {
        let fromData = [];
        let toData = [];
        let lineData = [];
        let fromLong = data[i].from.longitude;
        let fromLat = data[i].from.latitude;
        let toLong = data[i].to.longitude;
        let toLat = data[i].to.latitude;
        fromData = [[fromLong, fromLat, data[i].from.companyName]];
        companyList.push({
          longitude: fromLong,
          latitude: fromLat,
        });
        toData = [[toLong, toLat, data[i].to.companyName]];
        lineData = [
          {
            fromName: data[i].from.companyName,
            toName: data[i].to.companyName,
            goodsName: data[i].info.name,
            entrustId: data[i].info[0].entrustId,
            coords: [
              [fromLong, fromLat],
              [toLong, toLat],
            ],
          },
        ];
        companyList.push({
          longitude: toLong,
          latitude: toLat,
        });
        result.push({ fromData, toData, lineData });
      }
      // for (let i = 0; i < data.length; i++) {
      //   let fromData = [];
      //   let toData = [];
      //   let lineData = [];
      //   fromData = [
      //     [
      //       data[i].from.company_longitude,
      //       data[i].from.company_latitude,
      //       data[i].from.company_name,
      //     ],
      //   ];
      //   companyList.push({
      //     longitude: data[i].from.company_longitude,
      //     latitude: data[i].from.company_latitude,
      //   });
      //   toData = [
      //     [
      //       data[i].to.company_longitude,
      //       data[i].to.company_latitude,
      //       data[i].to.company_name,
      //     ],
      //   ];
      //   lineData = [
      //     {
      //       fromName: data[i].from.company_name,
      //       toName: data[i].to.company_name,
      //       goodsName: data[i].info.goods_name,
      //       batch: data[i].info.batch,
      //       coords: [
      //         [data[i].from.company_longitude, data[i].from.company_latitude],
      //         [data[i].to.company_longitude, data[i].to.company_latitude],
      //       ],
      //     },
      //   ];
      //   companyList.push({
      //     longitude: data[i].to.company_longitude,
      //     latitude: data[i].to.company_latitude,
      //   });
      //   result.push({ fromData, toData, lineData });
      // }
      this.companyList = companyList;
      return result;
    },
    getGeo() {
      if (this.companyList.length > 0) {
        setTimeout((_) => {
          let pointArr = [];
          this.companyList.forEach((item) => {
            pointArr.push(new window.BMap.Point(item.longitude, item.latitude));
          });
          let echarts = this.$refs.mapRef.echarts;
          let map = echarts.getModel().getComponent("bmap").getBMap();
          let view = map.getViewport(eval(pointArr));
          let mapZoom = view.zoom > 2 ? view.zoom - 1 : view.zoom;
          let centerPoint = view.center;
          map.centerAndZoom(centerPoint, mapZoom);
        }, 200);
      }
    },
    verifyHash() {
      this.hashFlag = !this.hashFlag;
    },
  },
};
</script>
<style scoped lang="scss">
.container {
  height: 100%;
}
.input_box {
  border: 2px solid #409eff;
  color: #f2f6fc;
  height: 40px;
  border-radius: 15px;
  text-align: center;
  vertical-align: middle;
  margin-right: 10px;
}
$color: #2194e0;
@keyframes sheen {
  0% {
    transform: skewY(-45deg) translateX(0);
  }
  100% {
    transform: skewY(-45deg) translateX(12.5em);
  }
}
.button {
  width: 80px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  text-decoration: none;
  color: #d3d7d4;
  border: 2px solid $color;
  font-size: 16px;
  display: inline-block;
  border-radius: 15px;
  transition: all 0.2s ease-in-out;
  position: relative;
  overflow: hidden;
  margin-right: 10px;

  &:before {
    content: "";
    background-color: rgba(255, 255, 255, 0.5);
    height: 100%;
    width: 3em;
    display: block;
    position: absolute;
    top: 0;
    left: -4.5em;
    transform: skewX(-45deg) translateX(0);
    transition: none;
  }

  &:hover {
    cursor: pointer;
    background-color: $color;
    color: #fff;
    border-bottom: 4px solid darken($color, 10%);

    &:before {
      transform: skewX(-45deg) translateX(13.5em);
      transition: all 0.5s ease-in-out;
    }
  }
}
input::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #afb4db;
}
input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #afb4db;
}
input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #afb4db;
}
input:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #afb4db;
}
.chain-block-box {
  position: absolute;
  top: 28px;
  left: 15px;
  z-index: 1099;
  padding: 5px 10px;
  background-color: #1a366a;
  color: #f2f6fc;
  cursor: default;
}
.hash-value {
  color: #409eff;
  cursor: pointer;
}
.hash-result {
  color: #67c23a;
  cursor: pointer;
}
.container {
  height: 100%;
  width: 100%;
}
.spread {
  height: 100%;
}
.daily-detail::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 8px;
  height: 8px;
}
.daily-detail::-webkit-scrollbar-thumb {
  border-radius: 5px;
  // background: rgba(255, 255, 255, 0.8);
  background: #bbb;
  transition: color 0.2s ease;
  cursor: pointer;
}
</style>
